import React from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  Text,
  Heading,
  VStack,
  Box,
  StackDivider,
  Center,
  Link,
} from "@chakra-ui/react";
import Layout from '../components/Layout'
import data from '../data/items'

export default function Home() {
  const idList = data.result.items
  const items = data.entities.items
  return (
    <Layout>
      <Center>
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
          w="100%"
          align="stretch"
          justify="center"
        >
          {idList.map(id => (
            <Box key={`to-ep${id}`} p={[1, 5]} shadow="md" borderWidth="1px">
                <Link as={GatsbyLink} to={`/ep/${id}`}>
                  <Heading as="h2" size={['sm', 'm']} color="teal">
                    {items[id].title}
                  </Heading>
                </Link>
              <Text fontSize={['sm', 'md']}>
                {items[id].description}
              </Text>
            </Box>
          ))}
        </VStack>
      </Center>
    </Layout>
  );
}
